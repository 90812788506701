<template>
  <div>
    <vs-popup title="Pilih unit untuk ditandai ke siteplan" :active="isActive" v-on:update:active="emitModalIsActive">
      <div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <label class="ml-1 text-xs opacity-75">Pilih Unit</label>
            <vs-select autocomplete v-model="selectedItem" class="w-full">
              <vs-select-item v-for="(item, index) in data.propertiUnit"
                              :key="index"
                              :value="item"
                              :text="(item.id_siteplan ? '📌 ' : '') + item.no_unit"
                              :disabled="item.id_siteplan"
              />
            </vs-select>
          </div>
        </div>
        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
            <vs-button :disabled="isLoading || selectedItem == null" @click="save">
              <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
              <span v-if="!isLoading">Simpan</span>
            </vs-button>
          </div>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import PropertiUnitRepository from '@/repositories/master/properti-unit-repository'

export default {
  name: 'SiteplanAddMarker',
  props: ['isActive', 'data'],
  data () {
    return {
      isLoading: false,
      selectedItem: null
    }
  },
  methods: {
    save () {
      this.isLoading = true
      const params = {
        id: this.selectedItem.id, // id_properti_unit
        id_siteplan: this.data.siteplan.id,
        latitude: this.data.coordinates.latitude,
        longitude: this.data.coordinates.longitude
      }

      PropertiUnitRepository.updateCoordinates(params)
        .then(response => {
          this.selectedItem = response.data.data
          this.onSuccess()
          this.notifySuccess('Unit berhasil ditandai')
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    onSuccess () {
      this.emitSuccess()
      this.emitModalIsActive(false)
    },

    resetData () {
      Object.assign(this.$data, this.$options.data.call(this))
    },

    emitSuccess () {
      this.$emit('success', this.selectedItem)
    },

    emitModalIsActive (isActive) {
      this.resetData()
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
